<template>
  <div class="stamp-transactions">
    <Module-Header title="Stamp Transactions">
      <el-button class="export" @click="exportExcel"><img src="@/assets/images/link.png" />Export</el-button>
    </Module-Header>

    <div class="search-box">
      <el-input placeholder="Keywords(e.g. Member ID or eatery name)" 
        prefix-icon="el-icon-search" v-model="listQ.like_member_id_or_eatery_name" style="flex: 1"></el-input>

      <!-- <el-select v-model="listQ.eatery_group_id" clearable :placeholder="'All groups'" style="margin-left:20px">
        <el-option
          v-for="(item,index) in groups" :key="index" :label="item.label" :value="item.value">
        </el-option>
      </el-select> -->
      <el-select v-model="listQ.type" clearable :placeholder="'All types'" style="margin-left:20px">
        <el-option
          v-for="(item,index) in types" :key="index" :label="item.label" :value="item.value">
        </el-option>
      </el-select>

      <Date-picker label="Time" :joiningDate="listQ.start_date" @updateDate="changeDate"></Date-picker>

      <el-button class="search" icon="el-icon-search" @click="getData"> Search </el-button>
    </div>

    <div class="table-box">
      <el-table :data="tableData" ref="table" style="width:100%;">
        <el-table-column v-for="(its, idx) in tableCate" :key="idx"
          :prop="its.prop" :label="its.label" :min-width="its.width"> </el-table-column>
      </el-table>
      <Pagination
        :total="total"
        :autoScroll='false'
        :page.sync="listQ._start"
        :limit.sync="listQ._limit"
        layout="prev, pager, next"
        :pager-count="count"
        @pagination="getData"
      />
    </div>

  </div>
</template>

<script>
import { getStampTransactions } from '../../api/stampTransactions'
import exportToExcel from "@/utils/exportExcel";

export default {
  data() {
    return {
      tableCate: [
        {label: 'Transaction ID', prop: 'transactionNo', width: '150'},
        {label: 'Member ID', prop: 'memberID', width: '130'},
        {label: 'Eatery ID', prop: 'eateryNumber', width: '120'},
        {label: 'Eatery Name', prop: 'eateryName', width: '200'},
        {label: 'Eatery Group', prop: 'eateryGroupName', width: '180'},
        {label: 'Type', prop: 'stampType', width: '200'},
        {label: 'Stamps', prop: 'stamps', width: '110'},
        {label: 'Time', prop: 'createdAt', width: '150'},
      ],
      tableData: [],
      total: 0,
      count: 5,
      listQ: {
        like_member_id_or_eatery_name: '',
        eatery_group_id: '',
        eatery_id: '',
        type: '',
        start_date: '',
        end_date: '',
        _start: 1,
        _limit: 20,
      },
      form: {
        keywords: '',
      },
      groups:[],
      types:[
        {label: 'Reward 1', value: 0},
        {label: 'Reward 2', value: 1},
        {label: 'Plastic Free 1', value: 2},
        {label: 'Plastic Free 2', value: 3},
      ],
    }
  },
  computed: {
    adminUserInfo: function() {
      return this.$store.getters.adminUserInfo
    },
  },
  methods: {
    exportExcel() {
      let data = this.tableData,
      tHeader = [],
      filterVal = [],
      title = 'Stamp Transactions'

      this.tableCate.filter(its => {
        tHeader.push(its.label)
        filterVal.push(its.prop)
      })
      
      exportToExcel(data, title, tHeader, filterVal, () => {})
    },
    changeDate(s) {
      this.listQ.start_date = s ? new Date(s).format('yyyy-MM-dd') : ''
      this.listQ.end_date = s ? new Date(s).format('yyyy-MM-dd') : ''
    },
    getData() {
      let {listQ} = this, form = {
        like_member_id_or_eatery_name: listQ.like_member_id_or_eatery_name,
        eatery_group_id: listQ.eatery_group_id,
        eatery_id: listQ.eatery_id,
        type: listQ.type,
        start_date: listQ.start_date,
        end_date: listQ.end_date,
        _start: listQ._start - 1,
        _limit: listQ._limit,
      }
      
      getStampTransactions(form).then(v => {
        v.data.filter(its => {
          its.memberID = its.memberId
          its.eateryID = its.eateryId
          its.eateryGroupName = its.eateryGroupName.zhHant
          its.eateryName = its.eateryName.zhHant
          its.stamps = its.stamps == 0 ? 0
            : its.stampOperationType == 0 ? '+' + its.stamps : '-' + its.stamps
          
          its.stampType = this.types[its.type].label
          its.createdAt = new Date(its.completedAt).format('YYYY/MM/dd hh:mm:ss')
        })
        this.tableData = v.data
        this.total = Number(v.headers['x-total-count'])
      })
    }
  },
  mounted() {
    if(this.adminUserInfo.position == 2) {
      this.listQ.eatery_group_id = this.adminUserInfo.eateryGroupId
    } else if(this.adminUserInfo.position == 3) {
      this.listQ.eatery_id = this.adminUserInfo.eateryId
    } else {
      let {keysWord, id} = this.$route.query
      console.log(keysWord, id)
      this.listQ[id] = keysWord
    }

    this.getData()
  }
}
</script>

<style scoped lang="scss">
.stamp-transactions {
  // display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  .search-box{
    display: flex;
    font-size: 0.875rem;
    width: 100%;
    margin-top: 3.125rem;
    .search {
      margin-left: 1.25rem;
    }
  }

}
</style>